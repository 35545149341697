import { CountryCode } from '../enums/i18n/country-code.enum';
import { Locale, SyliusLocale } from '../enums/i18n/locale.enum';
import { ChannelCode } from '../models/dto/channel';
import { I18N } from '../models/sys/i18n';

export const i18nSettings: I18N[] = [
  {
    appLocale: Locale.fr,
    syliusLocale: SyliusLocale.fr,
    channelCode: ChannelCode.default,
    countryCode: CountryCode.fr,
    label: 'France',
    doFinder: {
      baseUrl: 'https://eu1-search.doofinder.com',
      hashId: '70fb0d8ce0f3f87889399d696fbccadb',
      token: 'cc206ed4ac7c40c02236f8299fc9c19e8ed2067a',
    },
    axeptio: {
      cookiesVersion: 'mavic-fr',
    },
  },
  {
    appLocale: Locale.de,
    syliusLocale: SyliusLocale.de,
    channelCode: ChannelCode.de,
    countryCode: CountryCode.de,
    label: 'Deutschland',
    doFinder: {
      baseUrl: 'https://eu1-search.doofinder.com',
      hashId: 'a762b1dfbae9d143b68090129d25ddcd',
      token: 'cc206ed4ac7c40c02236f8299fc9c19e8ed2067a',
    },
    axeptio: {
      cookiesVersion: 'mavic-de',
    },
  },
  {
    appLocale: Locale.es,
    syliusLocale: SyliusLocale.es,
    channelCode: ChannelCode.es,
    countryCode: CountryCode.es,
    label: 'España',
    doFinder: {
      baseUrl: 'https://eu1-search.doofinder.com',
      hashId: 'f23c558c765ceffbd530e676df670a2a',
      token: 'cc206ed4ac7c40c02236f8299fc9c19e8ed2067a',
    },
    axeptio: {
      cookiesVersion: 'mavic-es',
    },
  },
  {
    appLocale: Locale.it,
    syliusLocale: SyliusLocale.it,
    channelCode: ChannelCode.it,
    countryCode: CountryCode.it,
    label: 'Italia',
    doFinder: {
      baseUrl: 'https://eu1-search.doofinder.com',
      hashId: 'b6a8545ecf60f200bc5837d6433302af',
      token: 'cc206ed4ac7c40c02236f8299fc9c19e8ed2067a',
    },
    axeptio: {
      cookiesVersion: 'mavic-it',
    },
  },
  {
    appLocale: Locale.us,
    syliusLocale: SyliusLocale.us,
    channelCode: ChannelCode.us,
    countryCode: CountryCode.us,
    label: 'United States',
    doFinder: {
      baseUrl: 'https://eu1-search.doofinder.com',
      hashId: 'aed097bc58e72cbe96e94897627dcdb8',
      token: 'cc206ed4ac7c40c02236f8299fc9c19e8ed2067a',
    },
    axeptio: {
      cookiesVersion: 'mavic-en',
    },
  },
  {
    appLocale: Locale.jp,
    syliusLocale: SyliusLocale.jp,
    channelCode: ChannelCode.jp,
    countryCode: CountryCode.jp,
    label: '日本',
    doFinder: {
      baseUrl: 'https://eu1-search.doofinder.com',
      hashId: '751059ebe747078567bbb54525f4884f',
      token: 'cc206ed4ac7c40c02236f8299fc9c19e8ed2067a',
    },
    axeptio: {
      cookiesVersion: 'mavic-jp',
    },
  },
  {
    appLocale: Locale.int,
    syliusLocale: SyliusLocale.gb,
    channelCode: ChannelCode.int,
    countryCode: CountryCode.int,
    label: 'International',
    doFinder: {
      baseUrl: 'https://eu1-search.doofinder.com',
      hashId: 'b4ad646ae9423d97da526ecc4e590761',
      token: 'cc206ed4ac7c40c02236f8299fc9c19e8ed2067a',
    },
    axeptio: {
      cookiesVersion: 'mavic-en',
    },
  },
];
