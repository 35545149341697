export enum Locale {
  fr = 'fr-fr',
  de = 'de-de',
  es = 'es-es',
  it = 'it-it',
  us = 'en-us',
  jp = 'ja-jp',
  int = 'en-int',
}

export enum SyliusLocale {
  fr = 'fr_FR',
  de = 'de_DE',
  it = 'it_IT',
  es = 'es_ES',
  us = 'en_US',
  jp = 'ja_JP',
  gb = 'en_GB',
}
